.no-padding {
	padding: 0px;
}

.index-header {
	width: 100%;
	position: fixed;
	top: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	z-index: 1000;
	background: #fff;
}

#company-logo {
	padding: 5px;
}

.header-option {
	
}

.header-logo {
	padding: 10px auto;
	text-align: left;
	height: 100%;
}

.right-nav {
	padding: 0px;
}

.header-link a {
	text-decoration: none;
	color: #333;
}

.header-link-nochange {
	/*font-family: dincondensedmed;*/
	font-size: 14px;
	/*font-weight: 100;*/
	padding: 15px 5px;
	text-align: center;
	transition: all .25s ease 0s;
	/*letter-spacing: -1px;   */
	height: 100%;
}

.header-link-nochange:hover {
	background: rgba(0, 0, 0, 0.1);
}

.header-link {
	/*font-family: dincondensedmed;*/
	font-size: 12px;
	/*font-weight: 100;*/
	padding: 15px 5px;
	border-top: 4px solid #fff;
	text-align: center;
	transition: all .25s ease 0s;
	white-space: nowrap;
	height: 100%;
	cursor: pointer;
}

.header-link.active {
	background: rgba(0, 0, 0, 0.3);
	border-top: 4px solid rgba(0, 0, 0, 1);
}

.header-link.active:hover {
	background: #fff;
}

.header-link:hover {
	border-top: 4px solid rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.2);
}

.header-link:hover span {
	position: relative;
	top: -5px;
	transition: all .25s ease 0s;
}

.mobile-bars {
	font-size: 16px;
}

.left-border {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.right-border {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
	cursor: pointer;
}

.track-outer {
	position: relative;
}

.track-form, .login-form {
	position: absolute;
	top: 100%;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	background: #fff;
}

.profile {
	position: absolute;
	top: 100%;
	width: 50%;
	right: 0px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	background: #fff;
}

.profile-option {
	padding: 10px;
}

.profile-option a {
	text-decoration: none;
	color: #000;
	padding: 2px;
	display: block;
}

.profile-option a:hover {
	color: rgba(0, 0, 0, .7);
}

@media ( max-width : 480px) {
	.header-link.active {
		background: rgba(0, 0, 0, 0.3);
		border-top: 4px solid rgba(0, 0, 0, 0);
	}
	.header-link.active:hover {
		background: #fff;
	}
	.header-link:hover {
		border-top: 4px solid rgba(0, 0, 0, 0);
		background: rgba(0, 0, 0, 0.2);
	}
	.header-link:hover span {
		position: relative;
		top: -5px;
		transition: all .25s ease 0s;
	}
	.profile {
		top: 50px !important;
	}
}

.global-message-cover {
	position: absolute;;
	left: 0;
	top: 0;
	height: 100%;
	width: 100vw;
}

#cover-data {
	position: fixed;
	left: 50%;
	top: 150px;
	width: 50vw;
	background: rgba(0, 0, 0, .5);
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	-o-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	text-align: center;
	padding: 20px;
}

#message {
	font-size: 20px;
	color: rgba(255, 255, 255, 0.5);
}

.index-login-link{
	color: black;
	text-decoration: none;		
}