/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 20 Feb, 2017, 6:52:04 PM
    Author     : sud
*/

body{

}

.content-outer{
    padding-top: 40px;
    z-index: 1;
}



.cd-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.cd-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
}

.cd-main-content {
    /* you need to assign a min-height to the main content so that the children can inherit it*/
    height: 100%;
    position: relative;
    z-index: 1;
}

.cd-fixed-bg {
    position: relative;
    min-height: 75%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
}
.cd-fixed-bg div {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 1170px;
    text-align: center;
    font-size: 30px;
    font-size: 1.875rem;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    color: white;
}
.cd-fixed-bg.cd-bg-1 {
    background-image: url("/images/front1.jpg");

}
.cd-fixed-bg.cd-bg-2 {
    background-image: url("/images/front2.png");
}
.cd-fixed-bg.cd-bg-3 {
    background-image: url("/images/front3.jpg");
}


.cd-scrolling-bg {
    position: relative;
    padding: 4em 0;
    line-height: 1.6;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    z-index: 2;
}




.cd-container.footer {
    width: 100%;
    padding: 0px;
}

.cd-scrolling-bg.footer-outer{
    padding: 0px;
}


/*reponsive*/
@media (max-width: 480px) {


    .cd-fixed-bg {
        background-attachment: fixed;
        font-size: 10px;
    }

    .cd-fixed-bg p {
        font-size: 10px;
    }

    .cd-scrolling-bg {
        padding: 8em 0;
        font-size: 1.25rem;
        line-height: 2;
        font-weight: 300;
    }

    .cd-scrolling-bg p{
        font-size: 10px;
    }
}

/*footer*/

.footer{
    padding: 100px;
    width: 100%;
    position: relative;
    text-align: left;
    background: #272d32;
    /*   height:500px; */
}


.footer-content{
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    display:table;
}



.footer_div{
    width:20%;
    display:table-cell;
    vertical-align: top;
    padding: 10px;
}


.footer_div p{
    word-wrap: break-word;
    color:#C0C0C0;
    font-size: 12px;
    text-align: justify;
    padding:10px;
}
.footer ul{
    position: relative;
    margin:0;
    padding:10px;
    width: 100%;

}

.footer ul li{
    list-style: none;
}
.footer h3{
    color: #fff;
    font-size: 16px;
    padding:10px;
}

.footer a{
    text-decoration:none;
    color:#c0c0c0;
    font-size: 12px;
}


.footer a:hover {
    color:rgba(255,255,255,0.5);
}

.copyright-data{
    text-align: left;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
}


.social-link{
    height: 25px;
    display: inline-block;
}

.link{
    padding:8px;
    text-decoration: none;
}



.end-line-outer{
    background: #323a45;
}
.end-line{
    padding: 10px;
    width: 70%;
    margin: 0 auto;
}


.social-link{
    text-align: right;
}
.social-link div{
    width: 25px;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
}



.social-slide-fb {
    background-image: url('/images/fb.png');
    height: 25px;
    width: 25px;
    float: left;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.social-slide-fb:hover {
    background-position: 0px -25px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
}

.social-slide-tw {
    background-image: url('/images/tw.png');
    height: 25px;
    width: 25px;
    float: left;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.social-slide-tw:hover {
    background-position: 0px -25px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
}
.social-slide-wp {
    background-image: url('/images/wp.png');
    height: 25px;
    width: 25px;
    float: left;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.social-slide-wp:hover {
    background-position: 0px -25px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
}
.social-slide-linkedin {
    background-image: url('/images/linkedin.png');
    height: 25px;
    width: 25px;
    float: left;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.social-slide-linkedin:hover {
    background-position: 0px -25px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
}


.login-form{
    padding:20px;
}
.login-input-outer{
    padding: 10px 0px;
}

.login-input{
    width: 100%;
}

.no-decoration{
    text-decoration: none;
    color: #000;
}

.option-link-outer{
    display:flex;
    align-items:center;
}

.login-button{
    font-size: 12px;
}


/*-------------------------------------------------------------------    footer  responsive -mode --------------------------------------------------------------   */
@media only screen and (max-width:500px)
{

    .footer_div{
        display: block;
        width: 100%;
    }
    .footer ul li{
        display: inline;
    }

    .app-footer-image{
        display: none;
    }

    .social-link {
        display: block;
        width: 100%;
    }

    .login-form{
        top: 50px !important;
    }

    .login-input{
        border: 1px solid rgba(0,0,0,0.2);
    }
}